/*--- Session ---*/
export const NETWORK_ERROR = 'NETWORK_ERROR';

export const CHECK_SESSION = 'CHECK_SESSION';
export const SESSION_CHECKED = 'SESSION_CHECKED';
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_COMPLETED = 'LOGOUT_COMPLETED';

/*--- GET ---*/
export const START_FETCH_REPORTS = 'START_FETCH_REPORTS';
export const FETCH_REPORTS_FAILED_BY_SESSION =
  'FETCH_REPORTS_FAILED_BY_SESSION';
export const FETCH_REPORTS_FAILED = 'FETCH_REPORTS_FAILED';
export const FETCH_REPORTS_SUCCEEDED = 'FETCH_REPORTS_SUCCEEDED';
export const START_FETCH_INPUT_HISTORIES = 'START_FETCH_INPUT_HISTORIES';
export const FETCH_INPUT_HISTORIES_FAILED_BY_SESSION =
  'FETCH_INPUT_HISTORIES_FAILED_BY_SESSION';
export const FETCH_INPUT_HISTORIES_SUCCEEDED =
  'FETCH_INPUT_HISTORIES_SUCCEEDED';
export const FETCH_INPUT_HISTORIES_FAILED = 'FETCH_INPUT_HISTORIES_FAILED';

/*--- UPDATE ---*/
export const START_UPDATE = 'START_UPDATE';
export const UPDATE_REPORTS_FAILED_BY_SESSION =
  'UPDATE_REPORTS_FAILED_BY_SESSION';
export const UPDATE_REPORTS_FAILED = 'UPDATE_REPORTS_FAILED';
export const REPORTS_UPDATED = 'REPORTS_UPDATED';
export const EDIT_REPORT = 'EDIT_REPORT';
export const EDIT_ALL_REPORTS = 'EDIT_ALL_REPORTS';
export const CANCEL_EDIT_REPORT = 'CANCEL_EDIT_REPORT';
/*--- DELETE ---*/
export const START_DELETE = 'START_DELETE';
export const DELETE_REPORTS_FAILED_BY_SESSION =
  'DELETE_REPORTS_FAILED_BY_SESSION';
export const DELETE_REPORTS_FAILED = 'DELETE_REPORTS_FAILED';
export const REPORTS_DELETED = 'REPORTS_DELETED';

/*--- REVERT ---*/
export const START_RESET = 'START_RESET';
export const RESET_REPORT_FAILED_BY_SESSION = 'REVERT_REPORT_FAILED_BY_SESSION';
export const RESET_REPORT_FAILED = 'RESET_REPORT_FAILED';
export const REPORT_RESET = 'REPORT_RESET';

/*--- Paging ---*/
export const CHANGE_PAGE = 'CHANGE_PAGE';

/*--- General ---*/
export const REFRESH_REPORTS = 'REFRESH_REPORTS';
export const START_EDIT_VIEW = 'START_EDIT_VIEW';
export const END_EDIT_VIEW = 'END_EDIT_VIEW';

/*--- Note & URL ---*/
export const UPDATE_NOTE_URL = 'UPDATE_NOTE_URL';
export const DELETE_NOTE_URL = 'DELETE_NOTE_URL';
