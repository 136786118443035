import * as actionType from '../actions/actionTypes';

const initialState = {
  loading: false,
  targetCompany: '',
  targetYear: '',
  targetMonth: '',
  reports: [],
  noteUrls: [],
  orgNoteUrls: [],
  orgReports: [],
  newReport: null,
  createReportError: null,
  createReportFailed: false,
  selectedReport: null,
  sessionExpiredError: false,
  fetchReportsError: false,
  fetchReportsErrorObj: null,
  updating: false,
  updateReportError: null,
  updateReportFailed: false,
  deleteReportError: false,
  deleteReportFailed: false,
  resetReportError: null,
  resetReportFailed: false,
  requestErrorMessage: '',
  lastSearchConditions: {},
  editView: false,
  reportHistories: [],
  page: 0
};

export default (state = initialState, action = {}) => {
  const { payload } = action;
  switch (action.type) {
    case actionType.START_FETCH_REPORTS:
      return {
        ...initialState,
        loading: true,
        reports: [],
        orgReports: [],
        targetCompany: payload.company,
        targetYear: payload.year,
        targetMonth: payload.month
      };

    case actionType.FETCH_REPORTS_SUCCEEDED:
      return {
        ...state,
        reports: payload.reports,
        noteUrls: payload.noteUrls,
        orgReports: payload.reports,
        orgNoteUrls: payload.noteUrls,
        loading: false,
        editView: true
      };

    case actionType.FETCH_REPORTS_FAILED_BY_SESSION:
      return {
        ...state,
        reports: [],
        noteUrls: [],
        orgReports: [],
        orgNoteUrls: [],
        loading: false,
        sessionExpiredError: true
      };

    case actionType.FETCH_REPORTS_FAILED:
      return {
        ...state,
        reports: [],
        orgReports: [],
        noteUrls: [],
        orgNoteUrls: [],
        loading: false,
        fetchReportsError: true,
        fetchReportsErrorObj: action.error
      };

    case actionType.EDIT_REPORT:
      return {
        ...state,
        reports: [
          ...state.reports.map(item => {
            if (item.id === payload.report.id) {
              return payload.report;
            }
            return item;
          })
        ]
      };

    case actionType.EDIT_ALL_REPORTS:
      return {
        ...state,
        reports: payload.reports
      };

    case actionType.START_EDIT_VIEW:
      return {
        ...state,
        editView: true
      };

    case actionType.CANCEL_EDIT_REPORT:
      return {
        ...state,
        reports: [...state.orgReports],
        noteUrls: [...state.orgNoteUrls]
      };

    case actionType.START_UPDATE:
      return {
        ...state,
        requestErrorMessage: '',
        updating: true,
        sessionExpiredError: false,
        updateReportError: false
      };

    case actionType.UPDATE_REPORTS_FAILED_BY_SESSION:
    case actionType.DELETE_REPORTS_FAILED_BY_SESSION:
      return {
        ...state,
        updating: false,
        sessionExpiredError: true
      };

    case actionType.UPDATE_REPORTS_FAILED:
      return {
        ...state,
        updating: false,
        updateReportError: true
      };

    case actionType.REPORTS_UPDATED:
      return {
        ...state,
        updating: false
      };

    case actionType.START_DELETE:
      return {
        ...state,
        requestErrorMessage: '',
        updating: true,
        sessionExpiredError: false,
        deleteReportError: false
      };

    case actionType.DELETE_REPORTS_FAILED:
      return {
        ...state,
        updating: false,
        deleteReportError: true
      };

    case actionType.REPORTS_DELETED:
      return {
        ...state,
        updating: false,
        reports: [],
        orgReports: [],
        noteUrls: [],
        orgNoteUrls: [],
        targetCompany: '',
        targetYear: '',
        targetMonth: ''
      };

    case actionType.END_EDIT_VIEW:
      return {
        ...state,
        reports: [],
        orgReports: [],
        noteUrls: [],
        orgNoteUrls: [],
        editView: false
      };

    case actionType.REFRESH_REPORTS:
      return { ...initialState };

    case actionType.CHANGE_PAGE:
      return {
        ...state,
        page: payload.page
      };

    case actionType.START_RESET:
      return {
        ...state,
        requestErrorMessage: '',
        updating: true,
        sessionExpiredError: false,
        resetReportError: false
      };

    case actionType.RESET_REPORT_FAILED_BY_SESSION:
      return {
        ...state,
        updating: false,
        sessionExpiredError: true
      };

    case actionType.RESET_REPORT_FAILED:
      return {
        ...state,
        updating: false,
        resetReportError: true
      };

    case actionType.REPORT_RESET:
      return {
        ...state,
        updating: false
      };

    case actionType.UPDATE_NOTE_URL:
      const { noteUrl } = payload;
      const exist = state.noteUrls.find(
        item => item.announce_date === noteUrl.announce_date
      );

      return {
        ...state,
        noteUrls: exist
          ? state.noteUrls.map(noteUrl => {
              if (noteUrl.announce_date === payload.noteUrl.announce_date) {
                return payload.noteUrl; //更新
              }
              return noteUrl;
            })
          : [
              ...state.noteUrls,
              payload.noteUrl //追加
            ]
      };

    case actionType.DELETE_NOTE_URL:
      return {
        ...state,
        noteUrls: state.noteUrls.map(noteUrl => {
          if (noteUrl.id === payload.noteUrl.id) {
            return payload.noteUrl; //更新 -> 削除フラグON
          }
          return noteUrl;
        })
      };

    default:
      return state;
  }
};
