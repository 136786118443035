export const EDIT_MODE = {
  read: 0,
  create: 1,
  edit: 2,
  delete: 3
};

export const SERVER_ERROR = {
  ERROR_INVALID_REQUEST: 1,
  ERROR_INVALID_TICKER: 2,
  ERROR_NO_TICKER: 3,
  ERROR_INVALID_YM: 4,
  ERROR_ONE_YEAR_YM: 5,
  ERROR_NO_KPI_HEADER_ID: 6,
  ERROR_INVALID_KPI_HEADER_ID: 7,
  ERROR_NO_KPI_HEADER: 8,
  ERROR_NO_FIELD_ID: 9,
  ERROR_INVALID_FIELD_ID: 10,
  ERROR_NO_FIELD: 11
};

export const RUNNING_MODE = {
  Product: 'Product',
  Staging: 'Staging',
  Local: null
};
