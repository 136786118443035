import jsonPlaceholder from './jsonPlaceholder';

const localStorage = window.localStorage;

class reportApi {
  static fetchReports(company, year, month) {
    return jsonPlaceholder
      .get('/reports/', {
        headers: {
          Authorization: 'JWT ' + localStorage.ddrMonthlyReportToken
        },
        params: {
          company,
          year,
          month: month.toString().padStart(2, '0')
        }
      })
      .then(response => {
        return response;
      })
      .catch(error => {
        return { error };
      });
  }

  static save(reports, noteUrls, checkedByAdmin) {
    return jsonPlaceholder
      .post(
        '/reports/',
        {
          method: 'bulk_update',
          data: { reports, noteUrls, checked: checkedByAdmin }
        },
        {
          headers: {
            Authorization: 'JWT ' + localStorage.ddrMonthlyReportToken
          }
        }
      )
      .then(response => {
        return response;
      })
      .catch(error => {
        return { error };
      });
  }

  static delete_all(reports) {
    return jsonPlaceholder
      .post(
        '/reports/',
        {
          method: 'bulk_delete',
          data: reports
        },
        {
          headers: {
            Authorization: 'JWT ' + localStorage.ddrMonthlyReportToken
          }
        }
      )
      .then(response => {
        return response;
      })
      .catch(error => {
        return { error };
      });
  }

  static fetchInputHistories(kpi_header_id, company_field_id) {
    return jsonPlaceholder
      .get('/reports/recent_three/', {
        headers: {
          Authorization: 'JWT ' + localStorage.ddrMonthlyReportToken
        },
        params: {
          kpi_header_id,
          company_field_id
        }
      })
      .then(response => {
        return response;
      })
      .catch(error => {
        return { error };
      });
  }

  static reset(report) {
    return jsonPlaceholder
      .post(
        '/reports/',
        {
          method: 'reset',
          data: { report }
        },
        {
          headers: {
            Authorization: 'JWT ' + localStorage.ddrMonthlyReportToken
          }
        }
      )
      .then(response => {
        return response;
      })
      .catch(error => {
        return { error };
      });
  }
}

export default reportApi;
