import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import { login } from '../actions/sessionAction';
import green from '@material-ui/core/colors/green';
import CircularProgress from '@material-ui/core/CircularProgress';
import Confirm from './Confirm';

const styles = theme => ({
  base: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(3)
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  }
});

class Login extends React.Component {
  state = { email: '', password: '' };
  // state = { email: 'nekki', password: 'testtest', showLoginFailed: false };
  onFormSubmit = event => {
    event.preventDefault();
    const { email, password } = this.state;
    this.loginExecute(email, password);
  };

  async loginExecute(email, password) {
    await this.props.login(email, password);
    if (this.props.loginFailed) {
      this.setState({
        showLoginFailed: true,
        errTitle: 'ログインできません。',
        errMessage: 'ログインIDとパスワードを確認してください。'
      });
      return;
    }
    if (this.props.networkErr) {
      this.setState({
        showLoginFailed: true,
        errTitle: 'ネットワークエラー',
        errMessage:
          'サーバが応答していません。時間が経ってから再度ログインを実行してください。'
      });
      return;
    }

    this.props.history.push('/');
  }

  onLoginFailed = () => {
    this.setState({ showLoginFailed: false });
  };

  render() {
    const { classes, loading } = this.props;

    return (
      <div className={classes.base}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            ログイン
          </Typography>
          <form className={classes.form} onSubmit={this.onFormSubmit}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">ログインID</InputLabel>
              <Input
                id="email"
                name="email"
                autoComplete="email"
                onChange={e => this.setState({ email: e.target.value.trim() })}
                autoFocus
                value={this.state.email}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">パスワード</InputLabel>
              <Input
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={e =>
                  this.setState({ password: e.target.value.trim() })
                }
                value={this.state.password}
              />
            </FormControl>
            {/*<FormControlLabel*/}
            {/*  control={<Checkbox value="remember" color="primary" />}*/}
            {/*  label="Remember me"*/}
            {/*/>*/}
            <div className={classes.wrapper}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={loading}
              >
                ログイン
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </form>
        </Paper>
        <Confirm
          open={this.state.showLoginFailed}
          title={this.state.errTitle}
          message={this.state.errMessage}
          onOK={this.onLoginFailed}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.auth.loading,
  loginFailed: state.auth.loginFailed,
  errorMessage: state.auth.errorMessage,
  networkErr: state.auth.networkErr
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { login })
)(withRouter(Login));
