import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import Toolbar from '@material-ui/core/Toolbar';
import TrendUpIcon from '@material-ui/icons/TrendingUp';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

/*--- Actions ---*/
import { logout } from '../actions/sessionAction';
import classNames from 'classnames';
import { RUNNING_MODE } from '../shared/constants';

const styles = theme => ({
  appBar: {
    position: 'relative'
  },
  appBarDebug: {
    position: 'relative',
    backgroundColor: 'red'
  },
  appBarStaging: {
    position: 'relative',
    backgroundColor: '#d87d10'
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  icon: {
    marginRight: theme.spacing(2)
  },
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  userInfo: {
    margin: theme.spacing(2)
  }
});

class Header extends React.Component {
  onLogout = () => {
    this.props.logout();
    this.props.history.push('/login');
  };

  getTitle = () => {
    const { user, mode, isDebug } = this.props;
    let title = 'Monthly Report';

    if (!user) {
      return title;
    }

    if (isDebug) {
      if (mode === RUNNING_MODE.Product) {
        return `${title} 【DEBUG -> 本番機】`;
      } else if (mode === RUNNING_MODE.Staging) {
        return `${title} 【DEBUG -> 検証機】`;
      } else {
        return `${title} 【DEBUG -> 開発機】`;
      }
    } else {
      if (mode === RUNNING_MODE.Product) {
        return title;
      } else if (mode === RUNNING_MODE.Staging) {
        return `${title} 【検証機】`;
      } else {
        return `${title} 【開発機】`;
      }
    }
  };

  render() {
    const { user, classes, isDebug, mode } = this.props;
    return (
      <div className={classes.root}>
        <AppBar
          position="static"
          className={classNames(
            {
              [classes.appBar]: !isDebug && mode === RUNNING_MODE.Product
            },
            {
              [classes.appBarStaging]: !isDebug && mode === RUNNING_MODE.Staging
            },
            {
              [classes.appBarDebug]:
                isDebug ||
                (mode !== RUNNING_MODE.Product && mode !== RUNNING_MODE.Staging)
            }
          )}
        >
          <Toolbar>
            <TrendUpIcon className={classes.icon} />
            <div>
              <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                noWrap
              >
                {this.getTitle()}
              </Typography>
            </div>

            <div className={classes.grow}></div>
            {user ? (
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Typography className={classes.title}>{user}</Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.onLogout}
                  className={classes.userInfo}
                >
                  Logout
                </Button>
              </Grid>
            ) : null}
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  isDebug: state.auth.isDebug,
  mode: state.auth.mode
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { logout })
)(withRouter(Header));
