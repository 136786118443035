import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';

/*--- Actions ---*/
import { checkSession } from '../actions/sessionAction';
/*--- Components ---*/
import SearchBox from './SearchBox';
import ReportTable from './ReportTable';
import Progress from './Progress';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import UndoIcon from '@material-ui/icons/Undo';
import { withStyles } from '@material-ui/core';
import { endEditView } from '../actions/reportAction';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  button: {
    margin: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  actionArea: {
    padding: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  },
  input_space: {
    marginRight: theme.spacing(1)
  },
  input_value: {
    textAlign: 'right'
  },
  resetButton: {
    backgroundColor: '#007aff',
    color: 'white'
  }
});

class Top extends React.Component {
  componentDidMount() {
    const { user } = this.props;
    if (!user) {
      this.checkSession();
    }
  }

  async checkSession() {
    await this.props.checkSession();
    const { user, sessionChecking } = this.props;
    if (!sessionChecking && !user) {
      this.props.history.push('/login');
    }
  }

  static renderContents() {
    return (
      <div>
        <SearchBox />
        <ReportTable />
      </div>
    );
  }

  render() {
    const { user, sessionChecking, editView, classes } = this.props;

    return (
      <div>
        {editView ? (
          <Grid container direction="row" justify="center" alignItems="center">
            <Button
              variant="contained"
              color="primary"
              onClick={this.props.endEditView}
              className={classNames(classes.button, classes.resetButton)}
            >
              <UndoIcon
                className={classNames(classes.leftIcon, classes.iconSmall)}
              />
              検索に戻る
            </Button>
          </Grid>
        ) : (
          <Typography variant="h6" align="center" color="textPrimary" paragraph>
            更新対象ティッカー指定
          </Typography>
        )}

        {!user || sessionChecking ? (
          <Progress open={true} />
        ) : (
          Top.renderContents()
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  sessionChecking: state.auth.session,
  editView: state.report.editView
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    checkSession,
    endEditView
  })
)(Top);
