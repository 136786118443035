import './App.css';

import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { compose } from 'redux';
import { connect } from 'react-redux';

import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import { logout } from '../actions/sessionAction';

import Header from './Header';

import Top from './Top';
import Login from './Login';

const styles = theme => ({
  contentArea: {
    maxWidth: '95%',
    margin: '0 auto',
    padding: `${theme.spacing(3)}px 0 ${theme.spacing(3)}px`
  }
});

class App extends React.Component {
  componentDidMount() {
    // this.props.logout();
    // this.props.history.push('/login');
  }

  render() {
    const { classes, location } = this.props;
    console.log('PROCESS ENV 🐶🐶🐶🐶🐶🐶🐶🐶🐶');
    console.log(process.env);
    return (
      <React.Fragment>
        <CssBaseline />
        <Header />

        <main>
          <ReactCSSTransitionGroup
            transitionName="transition"
            transitionAppear={true}
            transitionEnter={true}
            transitionAppearTimeout={500}
            transitionEnterTimeout={500}
            transitionLeaveTimeout={500}
          >
            <div className={classes.contentArea}>
              <Switch location={location} key={location.key}>
                <Route exact path="/" component={Top} />
                <Route path="/login" component={Login} />
              </Switch>
            </div>
          </ReactCSSTransitionGroup>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loginChecked: state.auth.loginChecked
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { logout })
)(withRouter(App));
