import sessionAPI from '../api/sessionAPI';
import * as actionTypes from './actionTypes';

export const checkSession = () => dispatch => {
  dispatch({
    type: actionTypes.CHECK_SESSION
  });

  const response = sessionAPI.isLoggedIn();

  dispatch({
    type: actionTypes.SESSION_CHECKED,
    payload: {
      user: response.user,
      isAdmin: response.is_admin,
      token: response.token,
      isDebug: response.is_debug,
      isPowerUser: response.is_power_user,
      mode: response.mode
    }
  });
};

export const login = (email, password) => async dispatch => {
  dispatch({
    type: actionTypes.LOGIN
  });

  const response = await sessionAPI.login(email, password);

  if (response.error) {
    if (response.error.message === 'Network Error') {
      dispatch({
        type: actionTypes.NETWORK_ERROR,
        payload: {
          error: response.error
        }
      });
      return;
    }
    dispatch({
      type: actionTypes.LOGIN_FAILED,
      payload: {
        error: response.error
      }
    });
    return;
  }
  dispatch({
    type: actionTypes.LOGIN_SUCCEEDED,
    payload: {
      user: response.user,
      token: response.token,
      isAdmin: response.is_admin,
      isDebug: response.is_debug,
      isPowerUser: response.is_power_user,
      mode: response.mode
    }
  });
};

export const logout = () => dispatch => {
  sessionAPI.logout();
  dispatch({
    type: actionTypes.LOGOUT
  });
  dispatch({
    type: actionTypes.REFRESH_REPORTS
  });
};
