import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  onProgress: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 10000,
    backgroundColor: 'rgba(56, 56, 56, 0.7)'
  },
  circularArea: {
    textAlign: 'center',
    height: '100vh',
    width: '100vw',
    display: 'table-cell',
    verticalAlign: 'middle'
  },
  circular: {
    color: 'yellow'
  }
});

const Loading = props => {
  const { classes, withOverLay } = props;

  let tag = (
    <div className={classes.circularArea}>
      <CircularProgress size={100} className={classes.circular} />
    </div>
  );

  if (withOverLay) {
    tag = <div className={classes.onProgress}>{tag}</div>;
  }

  return tag;
};

Loading.propTypes = {
  withOverLay: PropTypes.bool.isRequired
};

export default withStyles(styles)(Loading);
