import React from 'react';
import ReactDOM from 'react-dom';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import uuid from 'react-uuid';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import DoneIcon from '@material-ui/icons/Done';
import Button from '@material-ui/core/Button';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import NoteIcon from '@material-ui/icons/Note';
import HttpIcon from '@material-ui/icons/Http';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
/*--- Actions ---*/
import {
  cancelEditReport,
  changePage,
  deleteNoteURL,
  deleteReports,
  endEditView,
  fetchReports,
  resetReport,
  saveReports,
  updateAllReports,
  updateNoteURL,
  updateReport
} from '../actions/reportAction';
import { logout } from '../actions/sessionAction';
import classNames from 'classnames';
/*--- Constants ---*/
import { EDIT_MODE } from '../shared/constants';
import Loading from './Loading';
import Confirm from './Confirm';
import EditNoteUrl from './EditNoteUrl';
import PreviewNoteUrl from './PreviewNoteUrl';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const filter_sokuh_all = '2';
const filter_sokuh_flg_on = '1';
const filter_sokuh_flg_off = '0';

const ROWS_PER_PAGE = 50;

const styles = theme => ({
  root: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
  },
  tableContainer: {
    width: '100%',
    padding: 0
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  },
  row_modified: {
    backgroundColor: '#fb92ff'
  },
  row_checked: {
    backgroundColor: '#87ff77'
  },
  button: {
    margin: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  actionArea: {
    padding: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  },
  input_space: {
    marginRight: theme.spacing(1)
  },
  input_value: {
    textAlign: 'right'
  },
  resetButton: {
    backgroundColor: '#007aff',
    color: 'white'
  },
  noteUrlButton: {
    backgroundColor: '#18a508',
    color: 'white'
  },
  noteIcon: {
    color: 'blue'
  },
  selectInput: {
    fontSize: 16
  },
  ul: {
    listStyleType: 'none',
    with: 100
  },
  li: {
    fontSize: '10pt'
  },
  buttonChecked: {
    color: 'white',
    backgroundColor: 'green'
  },
  historyCell: {
    minWidth: 150,
    padding: 5
  },
  paper: {
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  }
});

const cellNames = {
  announce_date: 'announce_date',
  announce_time: 'announce_time',
  value: 'value',
  note: 'note'
};

class ReportTable extends React.Component {
  state = {
    editMode: EDIT_MODE.read,
    showDialog: false,
    showDeleteConfirm: false,
    announceDateErrors: {},
    announceTimeErrors: {},
    showError: false,
    errTitle: '',
    errMessage: '',
    showHistories: false,
    editingReport: null,
    sokuho_view: filter_sokuh_all,
    showConfirmUpdate: false,
    showConfirmReset: false,
    resetTarget: null,
    showEditNoteUrl: false,
    noteUrl: {},
    editNoteUrlAnnounceDate: null,
    targetNoteUrl: null,
    editNoteUrl: null,
    readOnlyNoteUrl: false,
    openEditNoteUrl: false,
    anchorEl: null,
    openPopOver: false
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.loading) {
      return {
        editMode: EDIT_MODE.read
      };
    }
    if (prevState.editMode && !nextProps.editView) {
      return {
        editMode: EDIT_MODE.read
      };
    }
    return null;
  }

  hasRecordModified = () => {
    return this.props.noteUrls.find(item => {
      return item.modified && !item.checked;
    });
  };

  hasModified = reports => {
    const result = reports.find(item => {
      return item.modified === true && !item.checked;
    });
    return result;
  };

  onEditNoteUrlsClose = () => {
    this.setState({
      openEditNoteUrl: false
    });
  };

  onEditNoteUrlsUpdated = noteUrl => {
    console.log('💡💡💡💡💡💡💡💡💡');
    this.setState({ editNoteUrl: null });
    this.props.updateNoteURL(noteUrl);
    this.onEditNoteUrlsClose();
  };

  onEditAnnounceDate = (report, newValue, copyAll) => {
    // 文字列が空のときはerrorをクリア
    if (newValue.length === 0) {
      if (copyAll && !report.modified) {
        this.setState({
          announceDateErrors: {}
        });
      } else {
        this.setState({
          announceDateErrors: {
            ...this.state.announceDateErrors,
            [report.id]: false
          }
        });
      }
    }

    // 無効な入力はスルー
    if (newValue.length > 8) {
      return;
    }
    const updateValue = Number(newValue);
    if (isNaN(updateValue) || (updateValue !== 0 && !updateValue)) {
      return;
    }

    const pattern = /^([0-9]{1,8}|0)$/;
    if (newValue && !pattern.test(newValue)) {
      return;
    }

    // 入力を受け付けた後の有効性チェック
    let validationResult = false;

    if (newValue.length > 0) {
      if (newValue.length < 8) {
        validationResult = true;
        this.setState({
          announceDateErrors: {
            ...this.state.announceDateErrors,
            [report.id]: validationResult
          }
        });
      } else if (newValue.length === 8) {
        const inputYear = Number(newValue.substr(0, 4));
        const inputMonth = Number(newValue.substr(4, 2));
        const inputDate = Number(newValue.substr(6, 2));

        if (inputYear < 1990 || inputYear > 2099) {
          validationResult = true;
          this.setState({
            announceDateErrors: {
              ...this.state.announceDateErrors,
              [report.id]: validationResult
            }
          });
        } else {
          const date = new Date(inputYear, inputMonth - 1, inputDate);
          const dateYear = date.getFullYear();
          const dateMonth = date.getMonth() + 1;
          const dateDate = date.getDate();

          if (
            dateYear === inputYear &&
            dateMonth === inputMonth &&
            dateDate === inputDate
          ) {
            validationResult = false;
            this.setState({
              announceDateErrors: {
                ...this.state.announceDateErrors,
                [report.id]: validationResult
              }
            });
          } else {
            validationResult = true;
            this.setState({
              announceDateErrors: {
                ...this.state.announceDateErrors,
                [report.id]: validationResult
              }
            });
          }
        }
      }
    }

    // 先頭行の場合、編集前であればそれ以降の行に編集内容をコピー
    if (
      copyAll &&
      !report.disable_fe_copy_function &&
      !this.hasModified(this.props.reports)
    ) {
      const udateReports = this.props.reports.map(item => {
        return {
          ...item,
          announce_date: newValue
        };
      });

      let validationResults = {};
      this.props.reports.forEach(item => {
        validationResults[item.id] = validationResult;
      });

      this.setState({
        isUpdated: true,
        announceDateErrors: {
          ...validationResults
        }
      });

      this.props.updateAllReports(udateReports);
      return;
    }
    const updatedReport = {
      ...report,
      announce_date: updateValue
    };

    if (report.announce_date !== updateValue) {
      this.setState({ isUpdated: true });
    }

    this.props.updateReport(updatedReport);
  };

  onEditAnnounceTime = (report, newValue, copyAll) => {
    // 文字列が空のときはerrorをクリア
    if (newValue.length === 0) {
      if (copyAll && !report.modified) {
        this.setState({
          announceTimeErrors: {}
        });
      } else {
        this.setState({
          announceTimeErrors: {
            ...this.state.announceTimeErrors,
            [report.id]: false
          }
        });
      }
    }
    // 無効な入力はスルー
    if (newValue.length > 4) {
      return;
    }
    const updateValue = Number(newValue);
    if (isNaN(updateValue) || (updateValue !== 0 && !updateValue)) {
      return;
    }

    const pattern = /^([0-9]{1,4}|0)$/;
    if (newValue && !pattern.test(newValue)) {
      return;
    }

    // 入力を受け付けた後の有効性チェック
    const validTime = /^([0-1][0-9]|2[0-3])([0-5][0-9])$/;

    let validationResult = false;
    if (newValue.length > 0) {
      if (!validTime.test(newValue)) {
        validationResult = true;
      }
      this.setState({
        announceTimeErrors: {
          ...this.state.announceTimeErrors,
          [report.id]: validationResult
        }
      });
    }

    // 先頭行の場合、編集前であればそれ以降の行に編集内容をコピー
    if (
      copyAll &&
      !report.disable_fe_copy_function &&
      !this.hasModified(this.props.reports)
    ) {
      const udateReports = this.props.reports.map(item => {
        return {
          ...item,
          announce_time: newValue
        };
      });

      let validationResults = {};
      this.props.reports.forEach(item => {
        validationResults[item.id] = validationResult;
      });

      this.setState({
        isUpdated: true,
        announceTimeErrors: {
          ...validationResults
        }
      });
      this.props.updateAllReports(udateReports);
      return;
    }

    const updatedReport = {
      ...report,
      announce_time: newValue
    };
    if (report.announce_time !== newValue) {
      this.setState({ isUpdated: true });
    }
    this.props.updateReport(updatedReport);
  };

  onEditValue = (report, newValue) => {
    if (newValue === '') {
      const updatedReport = {
        ...report,
        value: null
      };
      if (report.value !== newValue) {
        this.setState({ isUpdated: true });
      }
      this.props.updateReport(updatedReport);
    }

    let pattern = /^([-]?[1-9]\d*|-|0)$/;
    if (report.allow_decimal) {
      pattern = /^([-]?[1-9]\d*|-|0|-0)(\.?)(\d*)$/;
    }

    if (!pattern.test(newValue)) {
      return;
    }

    const updatedReport = {
      ...report,
      value: newValue
    };
    if (report.value !== newValue) {
      this.setState({ isUpdated: true });
    }

    this.props.updateReport(updatedReport);
  };

  onEditNote = (report, newValue) => {
    const updateReport = {
      ...report,
      note: newValue
    };

    if (report.note !== newValue) {
      this.setState({ isUpdated: true });
    }

    this.props.updateReport(updateReport);
  };

  onCancelEdit = () => {
    this.setState({
      editMode: EDIT_MODE.read,
      announceDateErrors: {},
      announceTimeErrors: {}
    });
    this.props.cancelEditReport();
  };

  checkAnnounceDateError = () => {
    if (Object.values(this.state.announceDateErrors).includes(true)) {
      return true;
    }
    const { reports } = this.props;
    let hasError = false;

    reports.forEach(report => {
      if (
        report &&
        report.announce_date &&
        report.announce_date.toString().length !== 8
      ) {
        hasError = true;
      }
    });
    return hasError;
  };

  checkAnnounceTimeError = () => {
    return Object.values(this.state.announceTimeErrors).includes(true);
  };

  checkUrlSetError = () => {
    const { reports, noteUrls } = this.props;
    let hasError = false;
    reports.forEach(report => {
      if (
        report &&
        report.announce_date &&
        report.announce_date.toString().length === 8
      ) {
        const exist = noteUrls.find(noteUrl => {
          return (
            noteUrl.announce_date === report.announce_date.toString() &&
            noteUrl.urls &&
            noteUrl.urls.length > 0
          );
        });
        if (!exist) {
          hasError = true;
        }
      }
    });
    return hasError;
  };

  checkNoInputError = () => {
    const { reports } = this.props;
    let noInputCount = 0;
    reports.forEach(report => {
      if (!report.announce_date && !report.value) {
        noInputCount += 1;
      }
    });
    if (noInputCount === reports.length) {
      return true;
    }
    return false;
  };

  onConfirm = () => {
    let message;

    if (this.checkNoInputError()) {
      message = '公表日と値に入力があるレコードが存在しません。';
    } else if (this.checkAnnounceDateError() && this.checkAnnounceTimeError()) {
      message =
        '公表日・公表時間の入力値にエラーがあるため、保存することはできません。';
    } else if (this.checkAnnounceDateError()) {
      message = '公表日の入力値にエラーがあるため、保存することはできません。';
    } else if (this.checkAnnounceTimeError()) {
      message =
        '公表時間の入力値にエラーがあるため、保存することはできません。';
    } else if (this.checkUrlSetError()) {
      message = '発表日に入力がある場合は、URLを最低一つ入力してください。';
    }

    if (message) {
      this.setState({
        showError: true,
        errTitle: '入力エラー',
        errMessage: message
      });
      return;
    }

    this.setState({ showDialog: true });
  };

  /*--- 更新 ---*/
  onConfirmUpdate = () => {
    this.setState({ showConfirmUpdate: false });
    this.saveExecute(true);
  };

  onCancelConfirmUpdate = () => {
    this.setState({ showConfirmUpdate: false });
  };

  onSave = () => {
    this.setState({ showDialog: false });
    this.saveExecute(false);
  };

  async saveExecute(toConfirm) {
    const { reports, isAdmin, isPowerUser, noteUrls } = this.props;
    await this.props.saveReports(
      reports,
      noteUrls,
      (isAdmin || isPowerUser) && toConfirm
    );

    if (this.props.sessionExpiredError) {
      this.setState({
        showError: true,
        errTitle: 'セッションエラー',
        errMessage:
          'セッションの有効期限が切れました。再度ログインしてください。',
        hasSessionError: true
      });
      return;
    }
    if (this.props.updateReportError) {
      this.setState({
        showError: true,
        errTitle: 'レポート更新エラー',
        errMessage:
          'サーバエラーのためレポート更新に失敗しました。管理者にお問い合わせください。'
      });
      return;
    }

    this.setState({ editMode: EDIT_MODE.read });
    toast.success('KPIデータが更新されました。', {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    });
    this.props.fetchReports(
      this.props.targetCompany,
      this.props.targetYear,
      this.props.targetMonth
    );
  }

  onCancelSave = () => {
    this.setState({ showDialog: false, errTitle: '', errMessage: '' });
  };

  onDeleteAll = () => {
    this.setState({ showDeleteConfirm: false });
    this.deleteExecute();
  };

  async deleteExecute() {
    await this.props.deleteReports(this.props.reports);

    if (this.props.sessionExpiredError) {
      this.setState({
        showError: true,
        errTitle: 'セッションエラー',
        errMessage:
          'セッションの有効期限が切れました。再度ログインしてください。',
        hasSessionError: true
      });
      return;
    }
    if (this.props.deleteReportError) {
      this.setState({
        showError: true,
        errTitle: 'レポート更新エラー',
        errMessage:
          'サーバエラーのためレポート更新に失敗しました。管理者にお問い合わせください。'
      });
      return;
    }

    this.setState({ editMode: EDIT_MODE.read });
    toast.success('The report has been updated.', {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    });
  }

  onCancelDelete = () => {
    this.setState({ showDeleteConfirm: false });
  };

  onCloseError = () => {
    if (this.state.hasSessionError) {
      this.props.logout();
      this.props.history.push('/login');
      return;
    }
    this.setState({ showError: false });
  };

  /*--- リセット ---*/
  onConfirmReset = () => {
    this.setState({ showConfirmReset: false });
    this.resetExecute();
  };

  onCancelConfirmReset = () => {
    this.setState({ showConfirmReset: false, resetTarget: null });
  };

  async resetExecute() {
    const report = this.state.resetTarget;
    await this.props.resetReport(report);

    if (this.props.sessionExpiredError) {
      this.setState({
        showError: true,
        errTitle: 'セッションエラー',
        errMessage:
          'セッションの有効期限が切れました。再度ログインしてください。',
        hasSessionError: true
      });
      return;
    }
    if (this.props.resetReportError) {
      this.setState({
        showError: true,
        errTitle: 'リセットエラー',
        errMessage:
          'サーバエラーのため詳細のリセットに失敗しました。管理者にお問い合わせください。'
      });
      return;
    }

    toast.success('KPIデータの入力内容がリセットされました。', {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    });

    this.props.fetchReports(
      this.props.targetCompany,
      this.props.targetYear,
      this.props.targetMonth
    );
  }

  /*--- キー操作 ---*/
  onKeyDown = (keyCode, report, cellName, firstRow) => {
    let leftCellName, rightCellName;
    switch (cellName) {
      case cellNames.announce_date: {
        leftCellName = cellNames.value;
        rightCellName = cellNames.announce_time;
        break;
      }
      case cellNames.announce_time: {
        leftCellName = cellNames.announce_date;
        rightCellName = cellNames.value;
        break;
      }
      case cellNames.value: {
        leftCellName = cellNames.announce_time;
        rightCellName = cellNames.announce_date;
        break;
      }
      default: {
        leftCellName = cellNames.value;
        rightCellName = cellNames.announce_date;
      }
    }
    const currentPos = this.props.reports
      .map(item => {
        return item.id;
      })
      .indexOf(report.id);

    let nextItem;
    let refName;
    if (keyCode === 13 || keyCode === 40) {
      // Up
      nextItem = this.props.reports[currentPos + 1];
      if (!nextItem) {
        nextItem = this.props.reports[0];
      } else {
        // copy previous value
        let updateReport = null;
        if (cellName === cellNames.announce_date) {
          nextItem.announce_date = report.announce_date;
          updateReport = {
            ...nextItem,
            announce_date: report.announce_date
          };
        } else if (cellName === cellNames.announce_time) {
          nextItem.announce_time = report.announce_time;
          updateReport = {
            ...nextItem,
            announce_time: report.announce_time
          };
        } else if (cellName === cellNames.note) {
          nextItem.note = report.note;
          updateReport = {
            ...nextItem,
            note: report.note ? report.note.trim() : null
          };
        }
        if (updateReport) {
          this.props.updateReport(updateReport);
        }
      }
      refName = this.getRefName(cellName, nextItem.id);
    } else if (keyCode === 38) {
      // Down
      nextItem = this.props.reports[currentPos - 1];
      if (!nextItem) {
        nextItem = this.props.reports[this.props.reports.length - 1];
      }
      refName = this.getRefName(cellName, nextItem.id);
    } else if (keyCode === 37) {
      // Left
      nextItem = report;
      refName = this.getRefName(leftCellName, nextItem.id);
    } else if (keyCode === 39) {
      // Right
      nextItem = report;
      refName = this.getRefName(rightCellName, nextItem.id);
    }

    const target = ReactDOM.findDOMNode(this.refs[refName]);
    if (target && target.tagName === 'SELECT') {
      target.focus();
      return;
    }

    if (target) {
      const inputs = target.getElementsByTagName('input');
      if (inputs && inputs.length > 0) {
        inputs[0].focus();
      } else {
        const textareas = target.getElementsByTagName('textarea');
        if (textareas && textareas.length > 0) {
          textareas[0].focus();
        }
      }
    }
  };

  getRefName = (cellName, reportId) => {
    return `${cellName}_${reportId}`;
  };

  onChangeSokuhoFilter = event => {
    console.log(event.target.value);
    this.setState({ sokuho_view: event.target.value });
  };

  hasNoteUrl = announceDate => {
    const { noteUrls } = this.props;
    const target = noteUrls.find(item => {
      return (
        item.announce_date === announceDate &&
        (item.note || (item.urls && item.urls.length > 0))
      );
    });
    return !!target;
  };

  getTargetNoteUrl = announceDate => {
    if (this.hasNoteUrl(announceDate)) {
      const { noteUrls } = this.props;
      return noteUrls.find(item => item.announce_date === announceDate);
    }
    return {
      id: uuid(),
      announce_date: announceDate,
      urls: [],
      note: ''
    };
  };

  /* === < Buttons > =================================================================== */

  // TODO: separate this into a component
  renderButtons() {
    const { classes, isAdmin, reports, isPowerUser } = this.props;
    if (this.props.loading || !this.props.editView) {
      return null;
    }
    return (
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={6}>
          {reports && reports.length > 0 ? (
            <Grid
              container
              justify="space-between"
              className={classes.actionArea}
            >
              <div>
                <h3>{`${this.props.targetYear}年${this.props.targetMonth}月`}</h3>
                <h3>
                  {this.props.targetCompany} : {reports[0].company_name}
                </h3>
              </div>
            </Grid>
          ) : null}
        </Grid>
        <Grid item xs={6}>
          <Grid container justify="flex-end" className={classes.actionArea}>
            {this.state.editMode === EDIT_MODE.edit ? (
              <div>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={this.onCancelEdit}
                >
                  <CancelIcon
                    className={classNames(classes.leftIcon, classes.iconSmall)}
                  />
                  キャンセル
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.onConfirm}
                >
                  <SaveIcon
                    className={classNames(classes.leftIcon, classes.iconSmall)}
                  />
                  保存
                </Button>
              </div>
            ) : null}

            {this.state.editMode === EDIT_MODE.read &&
            reports &&
            reports.length > 0 ? (
              <div>
                <FormControl component="fieldset" style={{ marginRight: 20 }}>
                  <FormLabel component="legend">速報フィルタ</FormLabel>
                  <RadioGroup
                    aria-label="sokuho_type"
                    name="sokuho_type"
                    value={this.state.sokuho_view}
                    onChange={this.onChangeSokuhoFilter}
                    row
                  >
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="すべて"
                    />
                    <FormControlLabel value="1" control={<Radio />} label="1" />
                    <FormControlLabel value="0" control={<Radio />} label="0" />
                  </RadioGroup>
                </FormControl>
                {isAdmin ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => this.setState({ showDeleteConfirm: true })}
                    className={classes.button}
                  >
                    <DeleteIcon
                      className={classNames(
                        classes.leftIcon,
                        classes.iconSmall
                      )}
                    />
                    全削除
                  </Button>
                ) : null}
                {(isAdmin || isPowerUser) &&
                (this.hasModified(reports) || this.hasRecordModified()) ? (
                  <Button
                    variant="contained"
                    onClick={() => this.setState({ showConfirmUpdate: true })}
                    className={classNames(
                      classes.button,
                      classes.buttonChecked
                    )}
                  >
                    <DoneIcon
                      className={classNames(
                        classes.leftIcon,
                        classes.iconSmall
                      )}
                    />
                    全確定
                  </Button>
                ) : null}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.setState({ editMode: EDIT_MODE.edit })}
                  className={classes.button}
                >
                  <EditIcon
                    className={classNames(classes.leftIcon, classes.iconSmall)}
                  />
                  編集
                </Button>
              </div>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  /* === < Cells > =================================================================== */
  renderAnnounceDate(report, index) {
    const { classes } = this.props;
    const editNoteUrl = this.getTargetNoteUrl(report.announce_date);

    if (this.state.editMode === EDIT_MODE.read) {
      return (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ padding: 0 }}
        >
          {report.announce_date}
          <IconButton
            onClick={() => {
              if (!editNoteUrl.announce_date) {
                return;
              }
              this.setState({
                readOnlyNoteUrl: true,
                editNoteUrlAnnounceDate: report.announce_date.toString(),
                openEditNoteUrl: true
              });
            }}
          >
            {editNoteUrl && editNoteUrl.urls && editNoteUrl.urls.length > 0 ? (
              <HttpIcon className={classes.noteIcon} />
            ) : null}
            <NoteIcon
              className={
                this.hasNoteUrl(report.announce_date) ? classes.noteIcon : null
              }
            />
          </IconButton>
        </Grid>
      );
    }
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ padding: 0 }}
      >
        <TextField
          id="announce_date"
          type="search"
          ref={this.getRefName(cellNames.announce_date, report.id)}
          margin="dense"
          value={report.announce_date ? report.announce_date : ''}
          onChange={e =>
            this.onEditAnnounceDate(
              report,
              e.target.value,
              this.props.page === 0 && index === 0
            )
          }
          inputProps={{
            style: {
              textAlign: 'right',
              cursor: 'pointer',
              paddingRight: 10,
              width: 105
            }
          }}
          onKeyDown={e =>
            this.onKeyDown(
              e.keyCode,
              report,
              cellNames.announce_date,
              index === 0
            )
          }
          placeholder="YYYYMMDD"
          error={this.state.announceDateErrors[report.id]}
        />
        {report.announce_date ? (
          <IconButton
            onClick={() => {
              if (this.checkAnnounceDateError()) {
                const message =
                  '公表日にエラーがある場合、Note & URLの入力ができません。';
                this.setState({
                  showError: true,
                  errTitle: '無効な公表日が含まれています。',
                  errMessage: message
                });
                return;
              }
              this.setState({
                readOnlyNoteUrl: false,
                editNoteUrlAnnounceDate: report.announce_date.toString(),
                openEditNoteUrl: true
              });
            }}
          >
            {editNoteUrl && editNoteUrl.urls && editNoteUrl.urls.length > 0 ? (
              <HttpIcon className={classes.noteIcon} />
            ) : null}
            <NoteIcon
              className={
                this.hasNoteUrl(report.announce_date) ? classes.noteIcon : null
              }
            />
          </IconButton>
        ) : null}
      </Grid>
    );
  }

  renderAnnounceTime(report, index) {
    if (this.state.editMode === EDIT_MODE.read) {
      return <div>{report.announce_time}</div>;
    }
    return (
      <TextField
        id="announce_time"
        type="search"
        ref={this.getRefName(cellNames.announce_time, report.id)}
        margin="dense"
        value={
          report.announce_time || report.announce_time === 0
            ? report.announce_time
            : ''
        }
        onChange={e =>
          this.onEditAnnounceTime(
            report,
            e.target.value,
            this.props.page === 0 && index === 0
          )
        }
        inputProps={{
          style: {
            textAlign: 'right',
            cursor: 'pointer',
            padding: 10,
            width: 70
          }
        }}
        onKeyDown={e =>
          this.onKeyDown(
            e.keyCode,
            report,
            cellNames.announce_time,
            index === 0
          )
        }
        placeholder="HHmm"
        error={this.state.announceTimeErrors[report.id]}
      />
    );
  }

  getHistoryItemTag(yearMonth, announceDate, value) {
    let ymStr;
    if (yearMonth && yearMonth.length === 6) {
      ymStr = `${yearMonth.substring(0, 4)}年${yearMonth.substring(4)}月`;
    } else {
      ymStr = '-';
    }
    const dateStr = !!announceDate ? announceDate : '-';
    const valueStr = !!value || value === 0 ? value : '-';
    return `${ymStr} : ${dateStr} : ${valueStr} `;
  }

  renderValue(report, index) {
    if (this.state.editMode === EDIT_MODE.read) {
      return <div>{report.value}</div>;
    }
    return (
      <TextField
        id="value"
        type="search"
        ref={this.getRefName(cellNames.value, report.id)}
        margin="dense"
        value={report.value || report.value === 0 ? report.value : ''}
        onChange={e => this.onEditValue(report, e.target.value)}
        inputProps={{
          style: {
            textAlign: 'right',
            cursor: 'pointer',
            padding: 10,
            width: 100
          }
        }}
        onKeyDown={e =>
          this.onKeyDown(e.keyCode, report, cellNames.value, index === 0)
        }
      />
    );
  }

  renderHistories = report => {
    return (
      <React.Fragment>
        {report.histories.map(item => (
          <div key={`${report.id}_${item.year_month}_${item.announce_date}`}>
            <Typography color="inherit" variant="caption">
              {this.getHistoryItemTag(
                item.year_month,
                item.announce_date,
                item.value
              )}
            </Typography>
          </div>
        ))}
      </React.Fragment>
    );
  };

  renderNote = (report, index) => {
    if (this.state.editMode === EDIT_MODE.read) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: report.note ? report.note.replace(/\n/g, '<br/>') : ''
          }}
        />
      );
    }
    return (
      <TextField
        id="note"
        type="search"
        ref={this.getRefName(cellNames.note, report.id)}
        margin="dense"
        multiline
        value={report.note ? report.note : ''}
        onChange={e => this.onEditNote(report, e.target.value)}
        inputProps={{
          style: {
            textAlign: 'left',
            cursor: 'pointer',
            padding: 10
          }
        }}
        onKeyDown={e =>
          this.onKeyDown(e.keyCode, report, cellNames.note, index === 0)
        }
        placeholder="備考"
      />
    );
  };

  // ページング系：現在のページのレコードのみを取り出す
  getCurrentReports = reports => {
    const { page } = this.props;
    if (!reports || reports.length <= ROWS_PER_PAGE) {
      return reports;
    }
    const startIndex = page * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;
    if (reports.length >= endIndex) {
      return reports.slice(startIndex, endIndex);
    }
    return reports.slice(startIndex);
  };

  // ページチェンジ
  handlePageChange = (event, newPage) => {
    this.props.changePage(newPage);
  };

  /* === < Main Render > =================================================================== */
  render() {
    const { classes, reports, updating, page, isAdmin } = this.props;
    const currentReports = this.getCurrentReports(reports);

    return (
      <div>
        {updating ? (
          <Loading withOverLay={true} />
        ) : (
          <Paper className={classes.root}>
            {this.renderButtons()}
            <Grid container justify="center">
              <Paper className={classes.tableContainer}>
                <TablePagination
                  rowsPerPageOptions={[ROWS_PER_PAGE]}
                  component="div"
                  count={reports.length}
                  rowsPerPage={ROWS_PER_PAGE}
                  page={page}
                  onChangePage={this.handlePageChange}
                />
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <CustomTableCell>ID</CustomTableCell>
                      <CustomTableCell>速報</CustomTableCell>
                      <CustomTableCell>セグメント</CustomTableCell>
                      <CustomTableCell>フィールド</CustomTableCell>
                      <CustomTableCell>単位</CustomTableCell>
                      <CustomTableCell>公表日</CustomTableCell>
                      <CustomTableCell>公表時間</CustomTableCell>
                      <CustomTableCell>値</CustomTableCell>
                      <CustomTableCell>過去３回入力値</CustomTableCell>
                      {isAdmin ? (
                        <CustomTableCell>リセット</CustomTableCell>
                      ) : null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentReports &&
                      currentReports
                        .filter(report => {
                          if (this.state.sokuho_view === filter_sokuh_all) {
                            return (
                              report.sokuho_flg ===
                                Number(filter_sokuh_flg_on) ||
                              report.sokuho_flg === Number(filter_sokuh_flg_off)
                            );
                          } else {
                            return (
                              report.sokuho_flg ===
                              Number(this.state.sokuho_view)
                            );
                          }
                        })
                        .map((report, index) => (
                          <TableRow
                            className={classNames(
                              {
                                [classes.row]:
                                  !report.modified && !report.checked
                              },
                              {
                                [classes.row_modified]: report.modified
                              },
                              {
                                [classes.row_checked]: report.checked
                              }
                            )}
                            key={report.id}
                          >
                            <CustomTableCell component="th" scope="row">
                              {report.id}
                            </CustomTableCell>
                            <CustomTableCell align="right">
                              {report.sokuho_flg}
                            </CustomTableCell>
                            <CustomTableCell align="right">
                              {report.business_segment_name}
                            </CustomTableCell>
                            <CustomTableCell align="right">
                              {report.field_name}
                            </CustomTableCell>
                            <CustomTableCell align="right">
                              {report.unit_name}
                            </CustomTableCell>
                            <CustomTableCell align="right">
                              {this.renderAnnounceDate(report, index)}
                            </CustomTableCell>
                            <CustomTableCell align="right">
                              {this.renderAnnounceTime(report, index)}
                            </CustomTableCell>
                            <CustomTableCell align="right">
                              {this.renderValue(report, index)}
                            </CustomTableCell>
                            <CustomTableCell
                              align="left"
                              className={classes.historyCell}
                            >
                              {this.renderHistories(report)}
                            </CustomTableCell>
                            {isAdmin ? (
                              <CustomTableCell>
                                {!report.checked && report.modified ? (
                                  <IconButton
                                    onClick={() => {
                                      this.setState({
                                        showConfirmReset: true,
                                        resetTarget: report
                                      });
                                    }}
                                  >
                                    <RefreshIcon />
                                  </IconButton>
                                ) : null}
                              </CustomTableCell>
                            ) : null}
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>

                <TablePagination
                  rowsPerPageOptions={[ROWS_PER_PAGE]}
                  component="div"
                  count={reports.length}
                  rowsPerPage={ROWS_PER_PAGE}
                  page={page}
                  onChangePage={this.handlePageChange}
                />
              </Paper>
            </Grid>
            {this.renderButtons()}
            <Confirm
              open={this.state.showDialog}
              title="レポートの更新"
              message="この内容で保存してよろしいですか?"
              onOK={this.onSave}
              onCancel={this.onCancelSave}
            />
            <Confirm
              open={this.state.showDeleteConfirm}
              title="レポートの削除"
              message="この年月のレポートを削除します。実行しますか？"
              onOK={this.onDeleteAll}
              onCancel={this.onCancelDelete}
            />
            <Confirm
              open={this.state.showConfirmUpdate}
              title="レポート更新の確定"
              message="この年月のレポートの変更を確定します。実行しますか？"
              onOK={this.onConfirmUpdate}
              onCancel={this.onCancelConfirmUpdate}
            />
            <Confirm
              open={this.state.showError}
              title={this.state.errTitle}
              message={this.state.errMessage}
              onOK={this.onCloseError}
            />
            <Confirm
              open={this.state.showNoUpdateDialog}
              title="更新エラー"
              message="変更されたアイテムはありません。"
              onOK={() => {
                this.setState({ showNoUpdateDialog: false });
              }}
            />
            <Confirm
              open={this.state.showConfirmReset}
              title="更新内容のリセット"
              message="この詳細について、更新内容をリセットします。実行しますか？"
              onOK={this.onConfirmReset}
              onCancel={this.onCancelConfirmReset}
            />
            {this.state.openEditNoteUrl ? (
              <EditNoteUrl
                show={this.state.openEditNoteUrl}
                announceDate={this.state.editNoteUrlAnnounceDate}
                noteUrls={this.props.noteUrls}
                readOnly={this.state.readOnlyNoteUrl}
                onClose={this.onEditNoteUrlsClose}
                onUpdated={this.onEditNoteUrlsUpdated}
              />
            ) : null}

            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
            <ToastContainer />
          </Paper>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  noteUrls: state.report.noteUrls.filter(item => !item.deleted),
  orgNoteUrls: state.report.orgNoteUrls,
  ...state.report,
  ...state.auth
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    updateReport,
    updateAllReports,
    cancelEditReport,
    saveReports,
    fetchReports,
    deleteReports,
    endEditView,
    logout,
    changePage,
    resetReport,
    updateNoteURL,
    deleteNoteURL
  })
)(withRouter(ReportTable));
