import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  circular: {
    color: 'yellow'
  }
});

const Progress = props => {
  const { classes, open } = props;

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperComponent="CircularProgress"
    >
      <CircularProgress size={100} className={classes.circular} />
    </Dialog>
  );
};

export default withStyles(styles)(Progress);
