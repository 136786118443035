import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    width: '100%',
    margin: 0,
    padding: theme.spacing(2)
  },
  container: {
    width: 500
  },
  note: {
    height: 300,
    borderLeft: '1px solid gray',
    padding: 10,
    overflow: 'auto'
  },
  urlList: {
    height: 300,
    overflow: 'auto'
  },
  urlListItem: {
    padding: 0
  },
  url: {
    paddingLeft: 5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
});

const PreviewNoteUrl = withStyles(styles)(props => {
  const { classes, noteUrl } = props;

  if (!noteUrl) {
    return null;
  }
  return (
    <Paper>
      <Grid container>
        <Grid item xs={6} className={classes.container}>
          <Grid>
            <List dense={true} className={classes.urlList}>
              {noteUrl.urls &&
                noteUrl.urls.map((url, index) => (
                  <ListItem key={index} className={classes.urlListItem}>
                    <Link href={url} target="_blank" className={classes.url}>
                      {url}
                    </Link>
                  </ListItem>
                ))}
            </List>
          </Grid>
        </Grid>
        <Grid item xs={6} className={classNames(classes.container)}>
          <div
            className={classes.note}
            dangerouslySetInnerHTML={{
              __html: noteUrl.note ? noteUrl.note.replace(/\n/g, '<br/>') : ''
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
});

export default PreviewNoteUrl;
