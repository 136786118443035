import jsonPlaceholder from './jsonPlaceholder';

const localStorage = window.localStorage;
const USER = 'ddrMonthlyReportUser';
const IS_ADMIN = 'ddrMonthlyReportUserIsAdmin';
const TOKEN = 'ddrMonthlyReportToken';
const IS_DEBUG = 'ddrMonthlyReportIsDebug';
const MODE = 'ddrMonthlyReportMode';
const IS_POWER_USER = 'ddrMonthlyReportIsPowerUser';

class sessionAPI {
  static isLoggedIn() {
    const {
      ddrMonthlyReportUser,
      ddrMonthlyReportToken,
      ddrMonthlyReportUserIsAdmin,
      ddrMonthlyReportIsDebug,
      ddrMonthlyReportIsPowerUser,
      ddrMonthlyReportMode
    } = localStorage;
    console.log(
      ddrMonthlyReportUser,
      ddrMonthlyReportToken,
      ddrMonthlyReportUserIsAdmin,
      ddrMonthlyReportIsDebug,
      ddrMonthlyReportIsPowerUser,
      ddrMonthlyReportMode
    );
    return {
      user: ddrMonthlyReportUser,
      token: ddrMonthlyReportToken,
      is_admin: ddrMonthlyReportUserIsAdmin === 'true',
      is_debug: ddrMonthlyReportIsDebug === 'true',
      is_power_user: ddrMonthlyReportIsPowerUser === 'true',
      mode: ddrMonthlyReportMode
    };
  }

  static login(username, password) {
    return jsonPlaceholder
      .post('/auth/', {
        username,
        password
      })
      .then(response => {
        localStorage.setItem(USER, response.data.user);
        localStorage.setItem(TOKEN, response.data.token);
        localStorage.setItem(IS_ADMIN, response.data.is_admin);
        localStorage.setItem(IS_DEBUG, response.data.is_debug);
        localStorage.setItem(IS_POWER_USER, response.data.is_power_user);
        localStorage.setItem(MODE, response.data.mode);
        return response.data;
      })
      .catch(error => {
        return { error };
      });
  }

  static logout() {
    localStorage.removeItem(USER);
    localStorage.removeItem(TOKEN);
  }
}

export default sessionAPI;
