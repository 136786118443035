import reportAPI from '../api/reportAPI';

import * as actionTypes from './actionTypes';

/*--- 年月でレポートを ---*/
export const fetchReports = (company, year, month) => async dispatch => {
  dispatch({
    type: actionTypes.START_FETCH_REPORTS,
    payload: { company, year, month }
  });

  const result = await reportAPI.fetchReports(company, year, month);

  if (result.error) {
    if (result.error.response && result.error.response.status === 401) {
      dispatch({
        type: actionTypes.FETCH_REPORTS_FAILED_BY_SESSION
      });
      return;
    }
    if (result.error.response && result.error.response.status === 400) {
      dispatch({
        type: actionTypes.FETCH_REPORTS_FAILED,
        error: result.error.response.data
      });
      return;
    }
    dispatch({
      type: actionTypes.FETCH_REPORTS_FAILED,
      error: {
        message:
          '予期しないエラーが発生しました。時間をおいて再度実行してください。'
      }
    });
    return;
  }

  dispatch({
    type: actionTypes.FETCH_REPORTS_SUCCEEDED,
    payload: { reports: result.data.reports, noteUrls: result.data.records }
  });
};

export const startEditView = () => {
  return {
    type: actionTypes.START_EDIT_VIEW
  };
};

export const endEditView = () => {
  return {
    type: actionTypes.END_EDIT_VIEW
  };
};

export const updateReport = report => {
  return {
    type: actionTypes.EDIT_REPORT,
    payload: { report }
  };
};

export const updateAllReports = reports => {
  return {
    type: actionTypes.EDIT_ALL_REPORTS,
    payload: { reports }
  };
};

export const cancelEditReport = () => {
  return {
    type: actionTypes.CANCEL_EDIT_REPORT
  };
};

/*--- 全保存 ---*/
export const saveReports = (
  reports,
  noteUrls,
  checkedByAdmin
) => async dispatch => {
  dispatch({
    type: actionTypes.START_UPDATE,
    payload: { reports, noteUrls }
  });

  // TODO: Checked By Adminのときに不具合。一旦外す
  // let updatedNUs = [...noteUrls];
  // if (!checkedByAdmin) {
  //   updatedNUs = noteUrls.filter(item => {
  //     // announce_dateの変更により、存在しなくなったannounce_dateをもつNoteUrlを除外
  //     const exist = reports.find(report => {
  //       return report.announce_date === Number(item.announce_date);
  //     });
  //     return !!exist;
  //   });
  // }

  const result = await reportAPI.save(reports, noteUrls, checkedByAdmin);

  if (result.error) {
    if (result.error.response && result.error.response.status === 401) {
      dispatch({
        type: actionTypes.UPDATE_REPORTS_FAILED_BY_SESSION
      });
      return;
    }
    dispatch({
      type: actionTypes.UPDATE_REPORTS_FAILED
    });
    return;
  }

  dispatch({
    type: actionTypes.REPORTS_UPDATED,
    payload: { reports: result.data }
  });
};

/*--- 全削除 ---*/
export const deleteReports = reports => async dispatch => {
  dispatch({
    type: actionTypes.START_DELETE,
    payload: { reports }
  });

  const result = await reportAPI.delete_all(reports);

  if (result.error) {
    if (result.error.response && result.error.response.status === 401) {
      dispatch({
        type: actionTypes.DELETE_REPORTS_FAILED_BY_SESSION
      });
      return;
    }
    dispatch({
      type: actionTypes.DELETE_REPORTS_FAILED
    });
    return;
  }

  dispatch({
    type: actionTypes.REPORTS_DELETED,
    payload: { reports: result.data }
  });
};

/*--- ページング ---*/
export const changePage = page => {
  return {
    type: actionTypes.CHANGE_PAGE,
    payload: { page }
  };
};

/*--- 1件リセット ---*/
export const resetReport = report => async dispatch => {
  dispatch({
    type: actionTypes.START_RESET,
    payload: { report }
  });

  const result = await reportAPI.reset(report);

  if (result.error) {
    if (result.error.response && result.error.response.status === 401) {
      dispatch({
        type: actionTypes.RESET_REPORT_FAILED_BY_SESSION
      });
      return;
    }
    dispatch({
      type: actionTypes.RESET_REPORT_FAILED
    });
    return;
  }

  dispatch({
    type: actionTypes.REPORT_RESET,
    payload: { reports: result.data }
  });
};

/*--- Note & URL ---*/
export const updateNoteURL = noteUrl => {
  return {
    type: actionTypes.UPDATE_NOTE_URL,
    payload: { noteUrl }
  };
};

export const deleteNoteURL = noteUrl => {
  return {
    type: actionTypes.DELETE_NOTE_URL,
    payload: { noteUrl }
  };
};
