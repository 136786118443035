import axios from 'axios';
import { RUNNING_MODE } from '../shared/constants';

let apiUrl = 'http://localhost:8000/api';

if (process.env.REACT_APP_MONTHLY_API_ENV === RUNNING_MODE.Staging) {
  apiUrl = 'https://stg-monthly.ddrdataentry.com/api';
} else if (process.env.REACT_APP_MONTHLY_API_ENV === RUNNING_MODE.Product) {
  apiUrl = 'https://monthly.ddrdataentry.com/api';
}

export default axios.create({
  baseURL: apiUrl
});
