import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Confirm from './Confirm';
import Link from '@material-ui/core/Link';

import uuid from 'react-uuid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import CancelIcon from '@material-ui/icons/Cancel';
import classNames from 'classnames';

const styles = theme => ({
  appBar: {
    position: 'relative'
  },
  editing: {
    backgroundColor: '#18a508'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  reportInfo: {
    margin: theme.spacing(2),
    flex: 1
  },
  root: {
    width: '100%',
    margin: 0,
    padding: theme.spacing(2)
  },
  container: {
    maxHeight: 440
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  margin: {
    margin: theme.spacing(1)
  },
  addButton: {
    backgroundColor: '#007aff',
    color: 'white'
  },
  button: {
    margin: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditNoteUrl = withStyles(styles)(props => {
  const MAX_CHARS = 8000;

  const defaultUrlHelp = '有効なURLを入力してください。';

  const {
    show,
    noteUrls,
    announceDate,
    onClose,
    onUpdated,
    readOnly,
    classes
  } = props;

  const [url, setUrl] = React.useState('');
  const [urlError, setUrlError] = React.useState(false);
  let noteUrl = noteUrls.find(item => item.announce_date === announceDate);

  if (!noteUrl) {
    noteUrl = {
      id: uuid(),
      announce_date: announceDate,
      urls: [],
      note: ''
    };
  }
  const [urls, setUrls] = React.useState(noteUrl.urls ? noteUrl.urls : []);
  const [note, setNote] = React.useState(noteUrl.note ? noteUrl.note : '');
  const [chars_left, setChars_left] = React.useState(MAX_CHARS);
  // const [announceDate] = React.useState(noteUrl.announce_date);
  const [urlHelper, setUrlHelper] = React.useState(defaultUrlHelp);
  const [showConfirmError, setShowConfirmError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const countBytes = str => {
    return encodeURIComponent(str).replace(/%../g, 'x').length;
  };

  const handleAddUrl = () => {
    setUrlError(false);
    setUrlHelper(defaultUrlHelp);
    if (!url) {
      return;
    }

    const new_url = url.replace(/,/g, '');
    if (!isUrl(new_url)) {
      setUrlError(true);
      return;
    }

    const existUrl = urls.find(_url => _url === new_url);
    if (!!existUrl) {
      setUrlError(true);
      setUrlHelper('すでにリストに登録されています。');
      return;
    }

    urls.push(new_url);
    setUrls(urls);
    setUrl('');
  };

  const isUrl = str => {
    return str.match(/(http[s]?):\/\/*/i) != null;
  };

  const handleDelete = targetUrl => {
    const newUrls = urls.filter(item => item !== targetUrl);
    setUrls(newUrls);
  };

  const handleNoteChange = e => {
    const _note = e.target.value;
    setChars_left(MAX_CHARS - countBytes(_note));
    setNote(_note.slice(0, MAX_CHARS));
  };

  const handleConfirmUpdate = () => {
    setErrorMessage('');
    const _noteUrl = {
      ...noteUrl,
      id: noteUrl.id ? noteUrl.id : uuid(),
      announce_date: announceDate,
      urls,
      note
    };
    onUpdated(_noteUrl);
  };

  return (
    <Dialog fullScreen open={show} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.editing}>
          <Typography variant="h6" className={classes.title}>
            Note & URL
          </Typography>
          <Button color="inherit" onClick={onClose}>
            閉じる
          </Button>
        </Toolbar>
      </AppBar>
      <Paper className={classes.root}>
        <Grid container>
          <Grid item xs={6} className={classes.container}>
            <TextField
              label="Announce Date"
              id="announce_date"
              type="search"
              margin="dense"
              value={announceDate}
              inputProps={{
                style: {
                  textAlign: 'right',
                  cursor: 'pointer',
                  paddingRight: 10,
                  width: 105,
                  maxLength: 4
                }
              }}
              disabled
            />
            {readOnly ? null : (
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item xs={10}>
                  <TextField
                    id="url"
                    label="Url"
                    fullWidth
                    type="search"
                    onChange={e => {
                      setUrl(e.target.value);
                    }}
                    value={url}
                    error={urlError}
                    helperText={urlHelper}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button variant="contained" onClick={handleAddUrl}>
                    <AddIcon />
                    追加
                  </Button>
                </Grid>
              </Grid>
            )}

            <Grid>
              <List dense={true}>
                {urls &&
                  urls.map((url, index) => (
                    <ListItem key={index}>
                      <Link href={url} target="_blank">
                        {url}
                      </Link>
                      {readOnly ? null : (
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => {
                              handleDelete(url);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  ))}
              </List>
            </Grid>
          </Grid>
          <Grid item xs={6} className={classes.container}>
            <TextField
              id="note"
              label="Note"
              fullWidth
              multiline
              rows={20}
              variant="outlined"
              onChange={handleNoteChange}
              value={note}
              disabled={readOnly}
            />
            <p>入力可能文字数: {chars_left}</p>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Button
            variant="contained"
            className={classes.button}
            onClick={onClose}
          >
            <CancelIcon
              className={classNames(classes.leftIcon, classes.iconSmall)}
            />
            {readOnly ? <span>閉じる</span> : <span>キャンセル</span>}
          </Button>
          {readOnly ? null : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirmUpdate}
            >
              編集完了
            </Button>
          )}
        </Grid>
        <Confirm
          open={showConfirmError}
          title="確定エラー"
          message={errorMessage}
          onOK={() => {
            setShowConfirmError(false);
          }}
        />
      </Paper>
    </Dialog>
  );
});

export default EditNoteUrl;
