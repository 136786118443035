import * as actionType from '../actions/actionTypes';

const initialState = {
  sessionChecking: false,
  networkErr: false,
  loading: false,
  loginFailed: false,
  errorMessage: '',
  token: null,
  user: null,
  isAdmin: false,
  isDebug: false,
  isPowerUser: false,
  mode: null
};

export default (state = initialState, action = {}) => {
  const { payload } = action;
  switch (action.type) {
    case actionType.NETWORK_ERROR:
      return {
        ...state,
        loading: false,
        networkErr: true
      };
    case actionType.CHECK_SESSION:
      return {
        ...state,
        sessionChecking: true
      };
    case actionType.SESSION_CHECKED:
      return {
        ...state,
        sessionChecking: false,
        user: payload.user,
        isAdmin: payload.isAdmin,
        isDebug: payload.isDebug,
        isPowerUser: payload.isPowerUser,
        mode: payload.mode,
        token: payload.token
      };
    case actionType.LOGIN:
      return {
        ...initialState,
        loading: true
      };
    case actionType.LOGIN_SUCCEEDED:
      return {
        ...state,
        loading: false,
        user: payload.user,
        isAdmin: payload.isAdmin,
        isDebug: payload.isDebug,
        isPowerUser: payload.isPowerUser,
        token: payload.token,
        mode: payload.mode,
        loginFailed: false
      };
    case actionType.LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        loginFailed: true,
        errorMessage: payload.error
      };
    case actionType.LOGOUT:
      return {
        ...state,
        loading: false,
        token: null,
        loginSucceeded: false,
        user: null,
        isAdmin: false,
        isDebug: false,
        mode: null
      };
    case actionType.LOGOUT_COMPLETED:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
