import React from 'react';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { fetchReports, startEditView } from '../actions/reportAction';
import { login } from '../actions/sessionAction';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import { withStyles } from '@material-ui/core/styles';
import Confirm from './Confirm';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  },
  searchButton: {
    backgroundColor: 'green',
    width: 400,
    color: 'white'
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  }
});

class SearchBox extends React.Component {
  constructor(props) {
    super(props);

    const date = new Date();

    let year = date.getFullYear();
    let month = date.getMonth();

    if (month === 0) {
      year -= 1;
      month = 12;
    }

    this.state = {
      company: '',
      year,
      month,
      companyErr: false,
      yearErr: false,
      monthErr: false,
      errTitle: '',
      errMessage: '',
      hasSessionError: false
    };
  }

  onEditYear = updateValue => {
    if (isNaN(updateValue)) {
      return;
    }
    this.setState({ year: updateValue.trim(), yearErr: false });
  };

  onEditMonth = updateValue => {
    if (isNaN(updateValue)) {
      return;
    }
    this.setState({
      month: updateValue.trim(),
      monthErr: false
    });
  };

  hasCompanyErr = () => {
    const { company } = this.state;

    return !company;
  };

  hasMonthErr = () => {
    const { month } = this.state;

    let numMonth = Number(month);
    return numMonth < 1 || numMonth > 12;
  };

  hasOneYearErr = () => {
    const { year, month } = this.state;
    const tenYearAgo = new Date();
    tenYearAgo.setFullYear(tenYearAgo.getFullYear() - 11);
    const tenYearAgoYMStr = tenYearAgo
      .getFullYear()
      .toString()
      .concat((tenYearAgo.getMonth() + 1).toString().padStart(2, '0'));

    const searchYMStr =
      year +
      Number(month)
        .toString()
        .padStart(2, '0');
    return searchYMStr < tenYearAgoYMStr;
  };

  onSearchButtonClick = () => {
    const { company, year, month } = this.state;
    const companyErr = this.hasCompanyErr();
    const yearErr = this.hasOneYearErr();
    const monthErr = this.hasMonthErr();

    if (companyErr || yearErr || monthErr) {
      this.setState({
        companyErr,
        yearErr,
        monthErr
      });
      return;
    }

    this.fetchReportsExecute(company, year, month);
  };

  async fetchReportsExecute(company, year, month) {
    await this.props.fetchReports(company, year, month);
    if (this.props.sessionExpiredError) {
      this.setState({
        showDialog: true,
        errTitle: 'セッションエラー',
        errMessage:
          'セッションの有効期限が切れました。再度ログインしてください。',
        hasSessionError: true
      });
      return;
    }
    if (this.props.fetchReportsError) {
      if (this.props.fetchReportsErrorObj) {
        this.setState({
          showDialog: true,
          errTitle: 'レポート取得エラー',
          errMessage: this.props.fetchReportsErrorObj.message
        });
        return;
      }
      this.setState({
        showDialog: true,
        errTitle: 'レポート取得エラー',
        errMessage:
          'サーバエラーのためレポート取得に失敗しました。管理者にお問い合わせください。'
      });
    }
    if (this.props.reports.length < 1) {
      this.setState({
        showDialog: true,
        errTitle: 'レポート取得結果',
        errMessage: '対象となるレコードが存在しないため、結果が0件です。'
      });
      return;
    }
    this.props.startEditView();
  }

  onConfirmRequired = () => {
    if (this.state.hasSessionError) {
      this.props.history.push('/login');
      return;
    }
    this.setState({ showDialog: false });
  };

  render() {
    const { classes, loading, editView } = this.props;

    if (editView) {
      return null;
    }

    return (
      <Paper className={classes.root}>
        <form className={classes.container} noValidate autoComplete="off">
          <Grid container>
            <Grid item xs={12}>
              <TextField
                id="standard-full-width"
                label="ティッカーコード"
                type="search"
                placeholder="例：30460"
                helperText="登録済みの有効なティッカーコードを入力してください。"
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                value={this.state.company}
                onChange={e =>
                  this.setState({
                    company: e.target.value.trim(),
                    companyErr: false
                  })
                }
                required
                error={this.state.companyErr}
              />
              <TextField
                id="standard-search"
                label="年"
                type="search"
                className={classes.textField}
                margin="normal"
                helperText="半角数字4桁（過去１０年以内）"
                value={this.state.year}
                onChange={e => this.onEditYear(e.target.value)}
                required
                error={this.state.yearErr}
              />
              <TextField
                id="standard-search"
                label="月"
                type="search"
                className={classes.textField}
                margin="normal"
                helperText="半角数字 1〜12"
                value={this.state.month}
                onChange={e => this.onEditMonth(e.target.value)}
                required
                error={this.state.monthErr}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="center" className={classes.wrapper}>
                <Button
                  variant="contained"
                  className={classNames(
                    classes.button,
                    classes.wide,
                    classes.searchButton
                  )}
                  onClick={this.onSearchButtonClick}
                  disabled={loading}
                >
                  <SearchIcon
                    className={classNames(classes.leftIcon, classes.iconSmall)}
                  />
                  検索実行
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </form>
        <Confirm
          open={this.state.showDialog}
          title={this.state.errTitle}
          message={this.state.errMessage}
          onOK={this.onConfirmRequired}
        />
      </Paper>
    );
  }
}

SearchBox.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  ...state.report
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { fetchReports, login, startEditView })
)(withRouter(SearchBox));
